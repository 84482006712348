import React from 'react';

// Packages
import { BrowserRouter as  Router, Routes, Route, Navigate } from 'react-router-dom';
import { ConfigProvider } from 'antd';

// Components
import Login from './components/auth/Login';

// Redux
import { Provider } from 'react-redux';
import store from './store';

const App = () => {
	return (
		<ConfigProvider
			theme={{
				token: {
					fontFamily: 'Montserrat, sans-serif',
					colorPrimary: '#f18b71',
					colorPrimaryBg: 'white'
				},
				components: {
					Alert: {
						colorErrorBg: '#FF5449',
						colorText: 'white'
					}
				}
			}}
		>
			<Provider store= { store }>
				<Router>
					<Routes>
						<Route path='/login' element={ <Login /> } exact />
						{/* Other */}
						<Route path='*' element={ <Navigate to='/login' replace /> } />
					</Routes>
				</Router>
			</Provider>
		</ConfigProvider>
	);
}

export default App;
