export const SPANISH_TRANSLATIONS = {
	// LOGIN PAGE
	login: {
		button: 'Iniciar Sesión'
	},
	// FORM INPUTS & PLACEHOLDERS
	form: {
		username: {
			placeholder: 'Nombre de usuario',
			rule: 'Por favor, ingresa tu nombre de usuario.'
		},
		password: {
			placeholder: 'Contraseña',
			rule: 'Por favor, ingresa tu contraseña.'
		},
	}
};
