export const ENGLISH_TRANSLATIONS = {
	// LOGIN PAGE
	login: {
		button: 'Login'
	},
	// FORM INPUTS & PLACEHOLDERS
	form: {
		username: {
			placeholder: 'Username',
			rule: 'Username is required'
		},
		password: {
			placeholder: 'Password',
			rule: 'Password is required'
		},
	}
};
