import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Alert, Button, Form, Input } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';
import { sha256 } from 'js-sha256';
import { useTranslation } from 'react-i18next';

// Components
import Icon from '../utils/Icon';

// Actions
import { user_login } from '../../redux/actions/authActions';

const Login = () => {
	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { login_loading, auth_errors } = useSelector(state => state.auth);

	const [form] = Form.useForm();

	const onFinish = () => {
		form
			.validateFields()
			.then((values) => {
				let body = { ...values };
				body.password = sha256(values.password);
				body.session_type = 2;

				dispatch(user_login(body));
			})
			.catch((info) => {
				console.error(info);
			});
	}
	
	return (
		<div className='form'>
			<Form
				form={form}
				name='normal_login'
				className='login-form'
				initialValues={{
					remember: true,
				}}
				onFinish={onFinish}
			>
				<img
					className='login-form-img'
					style={{ width: '130px' }}
					src={process.env.PUBLIC_URL + '/assets/KM_logo.png'}
					alt='/KM_logo'
				/>
				{auth_errors.hasOwnProperty('user_login') &&
					<Alert
						style={{ textAlign: 'start', marginBottom: '1.5rem' }}
						message={auth_errors.user_login.msg}
						type='error'
						icon={<CloseCircleFilled style={{ color: 'white' }} />}
						showIcon
					/>
				}
				<Form.Item
					style={{ textAlign: 'start' }}
					name='username'
					rules={[
						{
							required: true,
							message: t('form.username.rule'),
						},
					]}
				>
					<Input
						prefix={<Icon icon='person' size={20} color='#bfbfbf' marginEnd='0.4rem' />}
						placeholder={t('form.username.placeholder')}
					/>
				</Form.Item>
				<Form.Item
					style={{ textAlign: 'start' }}
					name='password'
					rules={[
						{
							required: true,
							message: t('form.password.rule'),
						},
					]}
				>
					<Input.Password
						prefix={<Icon icon='lock' size={20} color='#bfbfbf' marginEnd='0.4rem' />}
						type='password'
						placeholder={t('form.password.placeholder')}
					/>
				</Form.Item>

				<Form.Item>
					<Button
						className='login-form-button'
						type='primary'
						htmlType='submit'
						size='large'
						loading={login_loading}
					>
						{t('login.button')}
					</Button>
					{/* ¿Olvidaste la contraseña? <a href=''>Cambiar contraseña</a> */}
				</Form.Item>
			</Form>
		</div>
	);
}

export default Login;
