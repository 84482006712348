import Axios from 'axios';
import Cookies from 'universal-cookie';
import { jwtDecode } from 'jwt-decode';

import {
	LOGIN_LOADING_TRUE,
	LOGIN_LOADING_FALSE,
	LOGIN_SUCCESS,
	LOGIN_ERROR,
	SET_CURRENT_USER
} from '../types';

import isEmpty from '../../utils/isEmpty';

const cookies = new Cookies ();

// Set logged user
export const user_set_current = (decoded) => dispatch => {
	sessionStorage.setItem('isAuthenticated', !isEmpty(decoded));

	dispatch({
		type: SET_CURRENT_USER,
		payload: decoded
	})
}

// Log user in
export const user_login = (loginUserInfo) => dispatch => {
	dispatch({ type: LOGIN_LOADING_TRUE });	

	let url = process.env.REACT_APP_SERVER_URL + '/api/users/login';

	Axios.post(url, loginUserInfo)
	.then((res) => {
		dispatch({ type: LOGIN_LOADING_FALSE });
		
		process.env.REACT_APP_RUNTIME === "Production" || process.env.REACT_APP_RUNTIME === "Test" ?
			cookies.set (process.env.REACT_APP_JWT_KEY, res.data.token, {
				path: "/", 
				domain: '.kitchenandmanners.com',
				secure: true
			}) :
			cookies.set (process.env.REACT_APP_JWT_KEY, res.data.token, {
				path: "/", 
				// domain: ".localhost.com"
				domain: "localhost"
			});
		
		auth_token_set (res.data.token);						// set token to auth header
		let decoded = jwtDecode (res.data.token);	// decode token to get user data
		dispatch(user_set_current(decoded));				// set current user

		dispatch({ type: LOGIN_SUCCESS });
		
		window.location.href = process.env.REACT_APP_ADMIN;
	}).catch((err) => {
		console.error(err);
		dispatch({
			type: LOGIN_ERROR,
			payload: { type: 'user_login', msg: Object.entries(err.response.data).map(([key, value]) => value)[0] }
		})
		
		dispatch({ type: LOGIN_LOADING_FALSE });
	})
};

// Set auth token for each request
const auth_token_set = token => {
	// Apply to every request
	if (token) Axios.defaults.headers.common['Authorization'] = token;
	else delete Axios.defaults.headers.common['Authorization'];
};
