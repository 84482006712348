import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { ENGLISH_TRANSLATIONS } from './resources/englishTranslations';
import { SPANISH_TRANSLATIONS } from './resources/spanishTranslations';

i18next
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		detection: {
			order: ['localStorage', 'navigator'],
			caches: ['localStorage']
		},
		resources: {
			en: {
				translation: ENGLISH_TRANSLATIONS
			},
			es: {
				translation: SPANISH_TRANSLATIONS
			}
		},
		fallbackLng: 'en'
	});
